<template>
  <div>
    <div class="left-active-tab-cont">
      <b-select
        :options="menuItems"
        :selected="activeMenu"
        :show-tool-tip="false"
        :selected-text-icon-style="activeMenu.iconStyle"
        :selected-text-icon="activeMenu.icon"
        @change="updateActiveMenu"
      />
    </div>
    <div class="tabs-stage">
      <div id="tab-1" class="tab-cont" style="">
        <!-- <summary-panel
          v-if="activeMenu.tab === 'SUMMARY'"
          :show-diff="dates.compareWith"
        /> -->
        <dash-creatives-performance-table v-if="activeMenu.tab === 'CREATIVE'" />
        <dash-publishers-performance-table v-if="activeMenu.tab === 'PUBLISHER'" />
        <!-- <dash-channels-performance-table v-if="activeMenu.tab === 'CHANNEL'" /> -->
        <dash-audiences-performance-table v-if="activeMenu.tab === 'AUDIENCE'" />
        <dash-media-types-performance-table v-if="activeMenu.tab === 'MEDIA TYPE'" />
        <dash-ad-groups-performance-table v-if="activeMenu.tab === 'AD GROUP'" />
      </div>
    </div>
  </div>
</template>

<script>
import { sync, get } from 'vuex-pathify';
import { isBlank } from 'adready-api/helpers/common';
import config from '~/config';
import {
  LEFT_PANE_WINDOW_TYPES,
  WINDOW_TYPE_AUDIENCE,
  WINDOW_TYPE_CREATIVE,
  WINDOW_TYPE_PUBLISHER,
  WINDOW_TYPE_MEDIA_TYPE,
  WINDOW_TYPE_AD_GROUP,
} from '~/constant';
import gaEventsMixin from '~/components/mixins/ga-events-mixin';

export default {
  name: 'DashPerformancePanel',
  components: {
    BSelect: () => import(/* webpackChunkName: "b-select" */ '~/components/elements/b-select.vue'),
    DashAudiencesPerformanceTable: () =>
      import(
        /* webpackChunkName: "dash-audiences-performance-table" */ '~/components/dash-panels/performance/audiences-table.vue'
      ),
    DashCreativesPerformanceTable: () =>
      import(
        /* webpackChunkName: "dash-creatives-performance-table" */ '~/components/dash-panels/performance/creatives-table.vue'
      ),
    DashPublishersPerformanceTable: () =>
      import(
        /* webpackChunkName: "dash-publishers-performance-table" */ '~/components/dash-panels/performance/publishers-table.vue'
      ),
    DashMediaTypesPerformanceTable: () =>
      import(
        /* webpackChunkName: "dash-media-types-performance-table" */ '~/components/dash-panels/performance/media-types-table.vue'
      ),
    DashAdGroupsPerformanceTable: () =>
      import(
        /* webpackChunkName: "dash-ad-groups-performance-table" */ '~/components/dash-panels/performance/ad-groups-table.vue'
      ),
  },
  mixins: [gaEventsMixin],
  props: {
    tableData: {
      type: Array,
      required: false,
      default: () => [],
    },
    tableHeaders: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data() {
    return {
      activeMenu: { tab: WINDOW_TYPE_PUBLISHER, displayText: 'Publisher', icon: 'play-circle' },
      menuItems: [
        {
          name: 'Publisher',
          windowType: WINDOW_TYPE_PUBLISHER,
          icon: 'play-circle',
        },
        {
          name: 'Media Type',
          windowType: WINDOW_TYPE_MEDIA_TYPE,
          icon: 'photo-video',
        },
        {
          name: 'Creative',
          windowType: WINDOW_TYPE_CREATIVE,
          icon: 'ad',
        },
        // {
        //   name: 'Channel',
        //   windowType: WINDOW_TYPE_CHANNEL,
        //   icon: 'tv-retro',
        // },
        {
          name: 'Audience',
          windowType: WINDOW_TYPE_AUDIENCE,
          icon: 'users',
        },
        {
          name: 'Ad Group',
          windowType: WINDOW_TYPE_AD_GROUP,
          iconStyle: 'fas',
          icon: 'layer-group',
        },
      ],
    };
  },
  computed: {
    conversionWindowMap: sync('common/conversionWindowMap'),
    dates: get('dashboard/dates'),
  },
  watch: {
    activeMenu: {
      handler() {
        // reset/delete the state of the other conversion windows in store.
        if (LEFT_PANE_WINDOW_TYPES.includes(this.activeMenu.tab)) {
          LEFT_PANE_WINDOW_TYPES.forEach((k) => {
            if (k !== this.activeMenu.tab && !isBlank(this.conversionWindowMap[k])) {
              delete this.conversionWindowMap[k];
            }
          });
        }
      },
    },
  },
  mounted() {
    // eBus.$emit('active-tab-performance-panel', this.activeMenu);
  },
  methods: {
    updateActiveMenu(tab = {}) {
      const { name, icon, iconStyle } = tab;
      const nameUpperCase = name.toUpperCase();

      if (this.activeMenu.tab !== nameUpperCase) {
        this.activeMenu = { tab: nameUpperCase, displayText: name, icon, iconStyle };
        this.$emit('active-tab-performance-panel', nameUpperCase);
        if (config.gaEnabled()) {
          const gaEv = {
            tab_name: nameUpperCase,
          };
          this.fireGAEvent('flp_perf_pnl_tab_chg', gaEv, false);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@media screen {
  .noselect {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .opt-btn:hover {
    color: var(--primarycolor);
  }
}

/*! CSS Used fontfaces */
@font-face {
  font-family: 'FontAwesome';
  font-style: normal;
  font-weight: normal;
}
.nav-tabs {
  position: absolute;
  top: -5.5%;
  left: 0;
  display: inline-flex;
  min-width: 366px;
  max-width: 100%;
  height: 44px;
  padding-top: 2px;
  overflow-x: scroll;
  white-space: nowrap;
}
::v-deep .nav-tabs .tab-active a {
  border-top: 1px solid var(--primarycolor) !important;
}
.fa {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.fa-times:before {
  content: '\f00d';
}
.fa-clock-o:before {
  content: '\f017';
}
.tabs-stage {
  margin-top: -54px;
  text-align: left;
}
label {
  user-select: none;
}
input[type='checkbox'] {
  display: none;
}
input[type='checkbox'] + label:before {
  display: inline-block;
  font-family: 'FontAwesome';
}
input[type='checkbox'] + label:after {
  display: inline-block;
  font-family: 'FontAwesome';
}
input[type='checkbox'] + label:before {
  position: relative;
  top: -2px;
  left: 2px;
  z-index: 2;
  font-size: 14px;
  font-weight: 600;
  color: var(--primarycolor);
  letter-spacing: 10px;
  content: '\f00c';
  opacity: 0;
}
input[type='checkbox']:checked + label:before {
  font-weight: 600;
  letter-spacing: 10px;
  content: '\f00c';
  opacity: 1;
}
input[type='checkbox'] + label::after {
  position: absolute;
  left: 0px;
  font-size: 18px;
  font-weight: 500;
  color: rgba(150, 150, 150, 0.4);
  content: '\f1db';
}
input[type='checkbox']:checked + label::after {
  color: var(--primarycolor);
}
.tab-panel-right {
  position: absolute;
  right: 0px;
  z-index: 11;
  margin: -20px 10px 0px 0px;
}
.tab-opt-area {
  position: absolute;
  z-index: 11;
  width: 250px;
  min-height: 100px;
  margin: 9px 0px 0px -216px;
  color: #333;
  background-color: #fff;
  border-radius: 3px;
  box-shadow: 2px 6px 9px 0px rgba(0, 0, 0, 0.5);
}
.tab-opt-header {
  box-sizing: border-box;
  height: 40px;
  padding: 10px 20px;
  font-size: 14px;
  background-color: #eee;
  border-radius: 4px 4px 0px 0px;
}
.float-left {
  float: left;
}
.float-right {
  float: right;
}
.tab-opt-area:before {
  position: absolute;
  right: 0px;
  width: 0;
  height: 0;
  margin: -5px 21px 0px 0px;
  content: '';
  border-right: 5px solid transparent;
  border-bottom: 5px solid #eee;
  border-left: 5px solid transparent;
}
.p20 {
  padding: 20px;
  overflow: hidden;
}
.col-1 {
  float: left;
  width: 33.3333%;
}
.form-control {
  box-sizing: border-box;
  width: 60px;
  padding: 12px 15px;
  background-color: #f4f4f4;
  border: 1px solid #ccc;
}
.custom-checkbox {
  position: relative;
  display: inline-block;
}
.label-txt {
  font-size: 12px;
}
.col-2 {
  float: left;
  width: 60px;
}
.tab-opt-area {
  display: none;
}
.flp-btn:hover {
  color: #fff !important;
  background-color: #424242 !important;
}
.btn-size3 {
  padding: 10px 15px;
  font-size: 12px;
}
.flp-btn {
  display: inline-block;
  font-weight: 400;
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.07em;
  cursor: pointer;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: var(--primarycolor);
  -webkit-border-radius: 0px;
  border-radius: 3px;
  -webkit-transition: all 0.6s ease;
  -moz-transition: all 0.6s ease;
  -ms-transition: all 0.6s ease;
  -o-transition: all 0.6s ease;
  transition: all 0.6s ease;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-touch-callout: none;
}
.tab-cont-title {
  display: inline-block;
  padding-left: 15px;
  font-size: 15px;
  font-weight: 400;
  line-height: 1.1em;
}

// CSS for options - START

.flp-panel-top-right {
  position: absolute;
  right: 0;
  z-index: 11;
  margin: -20px 10px 0 0;
}
svg:not(:root).svg-inline--fa {
  overflow: visible;
}
.svg-inline--fa {
  display: inline-block;
  height: 1em;
  overflow: visible;
  font-size: inherit;
  vertical-align: -0.125em;
}
.svg-inline--fa.fa-w-16 {
  width: 1em;
}

// CSS for options - END

.fa.fa-clock-o:hover {
  color: var(--primarycolor) !important;
}
.left-active-tab-cont {
  position: absolute;
  top: -39px;
  left: 0px;
}
::v-deep .left-active-tab-cont .dropdown .select {
  color: #fff;
  background-color: #212429 !important;
  border: unset !important;
  border-radius: unset !important;
  border-top: 2px solid var(--primarycolor) !important;
  width: 170px;
}
::v-deep .left-active-tab-cont .dropdown .select.open {
  background-color: #383a44;
}
::v-deep .left-active-tab-cont .dropdown .selected-opt-wrapper {
  display: inline-flex;
}
::v-deep .left-active-tab-cont .dropdown .selected-opt-wrapper .selected-icon {
  margin-top: 3px;
  margin-right: 5px;
  width: 15px;
  color: #cad1d6;
}
::v-deep .left-active-tab-cont .dropdown-menu {
  width: 170px !important;
}
::v-deep .left-active-tab-cont .dropdown .dropdown-menu .norm-option {
  display: flex;
}
::v-deep .left-active-tab-cont .dropdown .dropdown-menu .norm-option .icon {
  margin-top: 2px;
  margin-right: 5px;
  margin-left: -5px;
  width: 15px;
}
</style>
